import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";

const OrderPage = () => (
  <Index>
    <SEO title="Order" />
    <Container id="product-overview-banner">
      <h1>Order</h1>
    </Container>
    <Container>
      <p>Placeholder</p>
    </Container>
  </Index>
)

export default OrderPage
